import { lazy } from "react";

export const Register = lazy(() => import("../containers/register"));
export const Login = lazy(() => import("../containers/login"));
export const OnBoading = lazy(() => import("../containers/onBoarding"));

export const Dashboard = lazy(() => import("../containers/dashboard"));

export const Sales = lazy(() => import("../containers/sales"));

export const Estimates = lazy(() => import("../containers/estimates"));



export const Invoices = lazy(() => import("../containers/invoices"));
export const Status = lazy(() => import("../containers/status"));

export const TaxInvoices = lazy(() => import("../containers/taxInvoice"));

export const Customers = lazy(() => import("../containers/customers"));
export const CreateCustomer = lazy(
  () => import("../containers/createCustomer")
);

export const CustomerStatements = lazy(
  () => import("../containers/customerStatements")
);
export const CustomerStatementDownload = lazy(
  () => import("../containers/customerStatementDownload")
);

export const Product = lazy(() => import("../containers/product"));
export const CreateProduct = lazy(
  () => import("../containers/createProductServices")
);
export const ProductReport = lazy(() => import("../containers/productReport"));

export const Vendors = lazy(() => import("../containers/vendors"));
export const VendorStatements = lazy(() => import("../containers/vendorStatements"));
export const VendorStatementDownload = lazy(
  () => import("../containers/vendorStatementDownload")
);

export const CreateVendor = lazy(() => import("../containers/createVendor"));

export const PurchaseBill = lazy(() => import("../containers/purchaseBill"));

export const VatBillsAdvisors = lazy(
  () => import("../containers/vatBillsAdvisors")
);
export const TaxFiling = lazy(() => import("../containers/taxFiling"));

export const MonthlyFilings = lazy(() => import("../containers/monthlyFilings"));
export const MonthlyFiling = lazy(() => import("../containers/monthlyFiling"))
export const CreateMonthlyFiling = lazy(
  () => import("../containers/createMonthlyFiling")
);

export const CashNBank = lazy(() => import("../containers/cashNBank"));
export const CreateCashNBank = lazy(
  () => import("../containers/createCashNBank")
);

export const ProtectedLayout = lazy(() => import("../layouts/protectedLayout"));
export const PublicLayout = lazy(() => import("../layouts/publicLayout"));
export const AuthenticationLayout = lazy(
  () => import("../layouts/authenticationLayout")
);

export const PageNotFound = lazy(() => import("../containers/pageNotFound"));

export const Transactions = lazy(() => import("../containers/transactions"));

export const TDSReport = lazy(() => import("../containers/tdsReport"));
export const Settings = lazy(() => import("../containers/settings"));
export const UpdateCompany = lazy(
  () => import("../components/settings/updateCompanyInfo")
);
export const UpdateUser = lazy(() => import("../components/settings/updateUserInformation"))
export const UpdateNote = lazy(() => import("../components/settings/updateVatbillNote"))
export const ChangePassword = lazy(() => import("../components/settings/changePassword"))

export const Download = lazy(() => import("../containers/download"))
export const ReceiptDownload = lazy(() => import("../containers/receiptDownload"))

export const CreditNote = lazy(() => import("../containers/creditNote"));
export const DebitNote = lazy(() => import("../containers/debitNote"));

export const ActivityLog = lazy(() => import("../containers/activityLog"));

export const CreateBills = lazy(
  () => import("../containers/createBills")
);
export const BillsPreview = lazy(
  () => import("../components/billsPreview")
);

export const Help = lazy(() => import("../containers/help"))

export const MaterializedView = lazy(() => import("../containers/materializedView"))

export const Expenses = lazy(() => import("../containers/expenses"));
export const CreateExpense = lazy(() => import("../containers/createExpense"))

export const Annex13 = lazy(() => import("../containers/annex13"))

export const Maintenance = lazy(() => import("../containers/maintenancePage"))