import _ from "lodash";

const useSetup = () => {
  let appVersion = "1.0.0";
  const setup = localStorage.getItem("setup");
  if (setup == undefined) {
    return;
  }
  let setupObj = JSON.parse(setup);

  let fiscalYears = _.find(setupObj, ['key', 'fiscal_years']);
  let fiscalYearsParsed = fiscalYears?.value ? JSON.parse(fiscalYears?.value) : [];
  appVersion = _.find(setupObj, ['key', 'app_version'])?.value;

  let currentFiscalYear = _.find(setupObj, ['key', 'current_fiscal_year']);
  let currentDateNp = _.find(setupObj, ['key', 'date_np']);
  let currentDateEn = _.find(setupObj, ['key', 'date_en']);
  let tomorrowDateNp = _.find(setupObj, ['key', 'date_tomorrow_np']);
  let prevFiscalYearEndDate = _.find(setupObj, ['key', 'prev_fiscal_end_date']);
  let maintenanceStatusObj = _.find(setupObj, ['key', 'maintenance_status'])
  let templateObj = _.find(setupObj, ['key', 'bill_template'])
  let billTemplateObj = _.find(setupObj, ['key', 'bill_template']);
  let isAlternateTemplate = billTemplateObj?.value === 'template2';
  let is80mm = templateObj?.value === 'template3';
  let panMode = _.find(setupObj, ['key', 'pan_mode'])
  return {
    appVersion: `${appVersion} 110223`,
    fiscalYears: fiscalYearsParsed,
    currentFiscalYear: currentFiscalYear?.value,
    currentDateNp: currentDateNp?.value?.split(' ')[0],
    currentDateEn: currentDateEn?.value,
    tomorrowDateNp: tomorrowDateNp?.value,
    prevFiscalYearEndDate: prevFiscalYearEndDate?.value,
    maintenanceStatus: maintenanceStatusObj?.value === 'on' ? true : false,
    panModeStatus: panMode?.value === 'on' ? true : false,
    invoiceTemplate: templateObj?.value,
    isAlternateTemplate: isAlternateTemplate,
    is80mm: is80mm,
    template: templateObj?.value
  }
}


export default useSetup;
