import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/app.scss";
// import { BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error'
import _ from 'lodash';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const httpLink = createUploadLink({
  uri: window.REACT_APP_API_ENDPOINT,
});

const history = createBrowserHistory({ window });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('vbToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {

  let has503: any = networkError?.toString()?.search('503');
  if (has503 > 0) {
    history.push('/maintenance')
  }

});

const client = new ApolloClient({
  link: from([
    authLink, errorLink, httpLink
  ]),
  cache: new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  })
});

ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter basename={window.REACT_APP_ROUTER_BASENAME}> */}
    <HistoryRouter history={history}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </HistoryRouter>
    {/* </BrowserRouter> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
