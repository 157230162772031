import * as Async from "../app/asyncComponent";
import PrivateLayout from "./../layouts/protectedLayout";

interface routeInterface {
  name: string;
  path: string;
  layout: React.FC | undefined;
  element: React.FC;
  hasHeader?: boolean;
  isEditPage?: boolean;
  isInvoice?: boolean;
  isConversionPage?: boolean;
  isTaxInvoice?: boolean;
  isPurchaseBill?: boolean;
  isEstimate?: boolean;
  hidePrimarySidebar?: boolean;
  isOnboarding?: boolean;
  isDebitNote?: boolean;
  isCreditNote?: boolean;
  isExpenseBill?: boolean;
  isVatbillModeOnly?: boolean;
}

export const commonRoutes: Array<routeInterface> = [
  {
    name: "Register",
    path: "/register",
    layout: Async.AuthenticationLayout,
    element: Async.Register,
  },
  {
    name: "Login",
    path: "/login",
    layout: Async.AuthenticationLayout,
    element: Async.Login,
  },
  {
    name: "Onboarding",
    path: "/onboarding",
    layout: Async.ProtectedLayout,
    element: Async.OnBoading,
    hidePrimarySidebar: true,
    isOnboarding: true,
  },
  {
    name: "Dashboard",
    path: "/",
    layout: Async.ProtectedLayout,
    element: Async.Dashboard,
  },
  {
    name: "Sales",
    path: "/sales",
    layout: Async.ProtectedLayout,
    element: Async.Sales,
  },
  {
    name: "Quotations",
    path: "/sales/quotations",
    layout: Async.ProtectedLayout,
    element: Async.Estimates,
  },
  {
    name: "Generate a Quotation",
    path: "/sales/quotations/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEstimate: true,
  },
  {
    name: "Edit Quotation",
    path: "/sales/quotations/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEditPage: true,
    isEstimate: true,
  },
  {
    name: "Quotation Preview",
    path: "sales/quotations/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    // element: Async.Download,
    isEstimate: true,
  },
  {
    name: "Quotation Preview2",
    path: "sales/quotations/preview",
    layout: Async.PublicLayout,
    element: Async.BillsPreview,
    isEstimate: true,
  },
  {
    name: "Purchase Bills",
    path: "/purchase/bills",
    layout: Async.ProtectedLayout,
    element: Async.PurchaseBill,
  },
  {
    name: "Generate Purchase bills",
    path: "/purchase/bills/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isPurchaseBill: true,
    // isConversionPage: true
  },
  {
    name: "Edit Purchase Bill",
    path: "/purchase/bills/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEditPage: true,
    isPurchaseBill: true,
  },
  {
    name: "Purchase Bill Preview",
    path: "purchase/bills/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isPurchaseBill: true,
  },
  {
    name: "Purchase Bill Preview",
    path: "purchase/bills/create/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isPurchaseBill: true,
  },
  {
    name: "Receipt Download",
    path: "payment/download",
    layout: Async.PublicLayout,
    // element: Async.BillsPreview,
    element: Async.ReceiptDownload,
  },
  {
    name: "Expenses",
    path: "/purchase/expenses",
    layout: Async.ProtectedLayout,
    element: Async.Expenses,
  },
  {
    name: "Add Expense",
    path: "/purchase/expenses/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateExpense,
  },
  {
    name: "Edit Expense Bill",
    path: "/purchase/expenses/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateExpense,
    isEditPage: true,
    isExpenseBill: true,
  },
  {
    name: "Expense Bill Preview",
    path: "purchase/expenses/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isExpenseBill: true,
  },
  {
    name: "Status",
    path: "/sales/status",
    layout: Async.ProtectedLayout,
    element: Async.Status,
  },
  {
    name: "Customers",
    path: "/sales/customers",
    layout: Async.ProtectedLayout,
    element: Async.Customers,
  },
  {
    name: "Add New Customer",
    path: "/sales/customers/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateCustomer,
    hasHeader: true,
  },
  {
    name: "Edit Customer",
    path: "/sales/customers/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateCustomer,
    hasHeader: true,
    isEditPage: true,
  },
  {
    name: "Customer Statements",
    path: "/sales/customer-statements",
    layout: Async.ProtectedLayout,
    element: Async.CustomerStatements,
  },
  {
    name: "Customer Statement Download",
    path: "/sales/customer-statements/download/:customerId",
    layout: Async.PublicLayout,
    element: Async.CustomerStatementDownload,
  },
  {
    name: "Products",
    path: "/product",
    layout: Async.ProtectedLayout,
    element: Async.Product,
  },
  {
    name: "Product Report",
    path: "/product/report",
    layout: Async.ProtectedLayout,
    element: Async.ProductReport,
  },
  {
    name: "Add a Product or Services",
    path: "/product/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateProduct,
  },
  {
    name: "Edit Product or Services",
    path: "/product/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateProduct,
    hasHeader: true,
    isEditPage: true,
  },

  {
    name: "Vendors",
    path: "/purchase/vendors",
    layout: Async.ProtectedLayout,
    element: Async.Vendors,
  },
  {
    name: "Add New Vendor",
    path: "/purchase/vendors/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateVendor,
    hasHeader: true,
  },
  {
    name: "Vendor Statements",
    path: "/purchase/vendor-statements",
    layout: Async.ProtectedLayout,
    element: Async.VendorStatements,
  },
  {
    name: "Vendor Statement Download",
    path: "/purchases/vendor-statements/download/:vendorId",
    layout: Async.PublicLayout,
    element: Async.VendorStatementDownload,
  },

  {
    name: "Edit Vendor",
    path: "/purchase/vendors/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateVendor,
    hasHeader: true,
    isEditPage: true,
  },
  {
    name: "Credit Note",
    path: "/credit-note",
    layout: Async.ProtectedLayout,
    element: Async.CreditNote,
  },
  {
    name: "Generate Credit Note",
    path: "/credit-note/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isCreditNote: true,
  },
  {
    name: "Download",
    path: "/:type/download/:id",
    layout: Async.PublicLayout,
    element: Async.Download,
  },
  {
    name: "Edit Credit Note",
    path: "/credit-note/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEditPage: true,
    isCreditNote: true,
  },
  {
    name: "Credit Note Preview",
    path: "/credit-note/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isCreditNote: true,
  },
  {
    name: "Debit Note",
    path: "/debit-note",
    layout: Async.ProtectedLayout,
    element: Async.DebitNote,
  },
  {
    name: "Generate Debit Note",
    path: "/debit-note/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isDebitNote: true,
  },
  {
    name: "Debit Note Preview",
    path: "/debit-note/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isDebitNote: true,
  },
  {
    name: "Edit Debit Note",
    path: "/debit-note/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEditPage: true,
    isDebitNote: true,
  },
  {
    name: "Transactions",
    path: "/transactions",
    layout: Async.ProtectedLayout,
    element: Async.Transactions,
  },
  {
    name: "Cash And Bank",
    path: "/cash-and-bank",
    layout: Async.ProtectedLayout,
    element: Async.CashNBank,
  },
  {
    name: "Cash and bank",
    path: "/cash-and-bank/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateCashNBank,
  },
  {
    name: "Edit Cash and bank",
    path: "/cash-and-bank/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateCashNBank,
    hasHeader: true,
    isEditPage: true,
  },

  {
    name: "NotFound",
    path: "*",
    layout: Async.PublicLayout,
    element: Async.PageNotFound,
  },
  {
    name: "Settings",
    path: "/settings",
    layout: Async.ProtectedLayout,
    element: Async.Settings,
  },
  {
    name: "Activity Log",
    path: "/activity-log",
    layout: Async.ProtectedLayout,
    element: Async.ActivityLog,
  },
  {
    name: "Edit Company Information",
    path: "/update-company",
    layout: Async.ProtectedLayout,
    element: Async.UpdateCompany,
  },
  {
    name: "Edit User Information",
    path: "/update-user",
    layout: Async.ProtectedLayout,
    element: Async.UpdateUser,
  },
  {
    name: "Edit Note",
    path: "/update-note",
    layout: Async.ProtectedLayout,
    element: Async.UpdateNote,
  },
  {
    name: "Change Password",
    path: "/change-password",
    layout: Async.ProtectedLayout,
    element: Async.ChangePassword,
  },
  {
    name: "Help",
    path: "/help",
    layout: Async.PublicLayout,
    element: Async.Help,
  },
  {
    name: "Maintenance",
    path: "/maintenance",
    layout: Async.PublicLayout,
    element: Async.Maintenance,
  },
  {
    name: "TDS Report",
    path: "/tds-report",
    layout: Async.ProtectedLayout,
    element: Async.TDSReport,
  },
];

export const vatModeRoute: Array<routeInterface> = [
  {
    name: "Tax Invoices",
    path: "/sales/tax-invoices",
    layout: Async.ProtectedLayout,
    element: Async.TaxInvoices,
  },
  {
    name: "Tax Invoice Download",
    path: "sales/tax-invoices/download/:id",
    layout: Async.PublicLayout,
    // element: Async.BillsPreview,
    element: Async.Download,
    isTaxInvoice: true,
  },
  {
    name: "Create Tax Invoice",
    path: "/sales/tax-invoices/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isTaxInvoice: true,
    // isConversionPage: true
  },
  {
    name: "Create Tax Invoice",
    path: "/sales/tax-invoices/convert-to-tax-invoice",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isTaxInvoice: true,
    isConversionPage: true,
  },
  {
    name: "Tax Invoice Preview",
    path: "sales/tax-invoices/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isTaxInvoice: true,
  },
  {
    name: "Monthly Filing",
    path: "/monthly-filing",
    layout: Async.ProtectedLayout,
    element: Async.MonthlyFilings,
  },
  {
    name: "Monthly Filing",
    path: "/monthly-filing/:id",
    layout: Async.ProtectedLayout,
    element: Async.MonthlyFiling,
  },
  {
    name: "Create Monthly Filing",
    path: "/monthly-filing/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateMonthlyFiling,
  },
  {
    name: "Annex13",
    path: "/annex-13",
    layout: Async.ProtectedLayout,
    element: Async.Annex13,
  },
  {
    name: "Tax Filing",
    path: "/tax-filing",
    layout: Async.ProtectedLayout,
    element: Async.TaxFiling,
  },
  {
    name: "Materialized View Report",
    path: "/materialized-view",
    layout: Async.ProtectedLayout,
    element: Async.MaterializedView,
  },
];

export const panModeRoute: Array<routeInterface> = [
  {
    name: "Invoices",
    path: "/sales/invoices",
    layout: Async.ProtectedLayout,
    element: Async.Invoices,
  },
  {
    name: "Invoice Download",
    path: "sales/invoices/download/:id",
    layout: Async.PublicLayout,
    element: Async.Download,
    isTaxInvoice: true,
  },
  {
    name: "Create Invoice",
    path: "/sales/invoices/create",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isInvoice: true,
  },
  {
    name: "Create Invoice",
    path: "/sales/invoices/convert-to-tax-invoice",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isTaxInvoice: true,
    isConversionPage: true,
  },
  {
    name: "Invoice Preview",
    path: "sales/invoices/preview",
    layout: Async.ProtectedLayout,
    element: Async.BillsPreview,
    isInvoice: true,
  },
  {
    name: "Edit Invoice",
    path: "/sales/invoices/:id/edit",
    layout: Async.ProtectedLayout,
    element: Async.CreateBills,
    isEditPage: true,
    isInvoice: true,
  },
];

export const routes: Array<routeInterface> = [
  // {
  //   name: "Quotation Download",
  //   path: "sales/quotations/preview/download",
  //   layout: Async.PublicLayout,
  //   // element: Async.BillsPreview,
  //   element: Async.Download,
  //   isEstimate: true,
  // },
  // {
  //   name: "Invoices",
  //   path: "/sales/invoices",
  //   layout: Async.ProtectedLayout,
  //   element: Async.Invoices,
  // },
  // {
  //   name: "Create Invoice",
  //   path: "/sales/invoices/create",
  //   layout: Async.ProtectedLayout,
  //   element: Async.CreateBills,
  //   isInvoice: true,
  // },
  // {
  //   name: "Create Invoice",
  //   path: "/sales/invoices/convert-to-invoice",
  //   layout: Async.ProtectedLayout,
  //   element: Async.CreateBills,
  //   isInvoice: true,
  //   isConversionPage: true,
  // },
  // {
  //   name: "Invoice Preview",
  //   path: "sales/invoices/preview",
  //   layout: Async.ProtectedLayout,
  //   element: Async.BillsPreview,
  //   isInvoice: true,
  // },
  // {
  //   name: "Invoice Download",
  //   path: "sales/invoices/preview/download",
  //   layout: Async.PublicLayout,
  //   // element: Async.BillsPreview,
  //   element: Async.Download,
  //   isInvoice: true,
  // },
  // {
  //   name: "Edit Invoice",
  //   path: "/sales/invoices/:id/edit",
  //   layout: Async.ProtectedLayout,
  //   element: Async.CreateBills,
  //   isEditPage: true,
  //   isInvoice: true,
  // },
  // {
  //   name: "VATBills Advisors",
  //   path: "/vatbills-advisors",
  //   layout: Async.ProtectedLayout,
  //   element: Async.VatBillsAdvisors,
  // },
];
