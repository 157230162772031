import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


// import "../node_modules/antd/dist/antd.less";
import "./App.less";
import "./styles/app.scss";
// import PublicRoute from './routes/publicRoute';
// import PrivateRoute from './routes/privateRoute';
import { routes, commonRoutes, panModeRoute, vatModeRoute } from "./configs/route.configs";
import useSetup from './hooks/useSetup';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const setup = useSetup();
  let routes = commonRoutes;
  if (setup?.panModeStatus) {
    routes = [...commonRoutes, ...panModeRoute]
  } else {
    routes = [...commonRoutes, ...vatModeRoute]
  }

  return (
    <>
      <Suspense
        fallback={
          <div className="fallback-container">
            <Spin indicator={antIcon} />
          </div>
        }
      >

        <Routes>
          {routes?.map((route, routeIndex) => {
            const {
              element: Element,
              layout: Layout,
              path,
              isEditPage,
              isInvoice,
              isTaxInvoice,
              isConversionPage,
              isPurchaseBill,
              isEstimate,
              isCreditNote,
              isDebitNote,
              hidePrimarySidebar,
              isOnboarding,
              isExpenseBill
            } = route;
            return (
              <Route
                key={routeIndex}
                path={path}
                element={
                  <Layout
                    hidePrimarySidebar={hidePrimarySidebar}
                    isOnboarding={isOnboarding}
                    pageTitle={route?.name}
                  >
                    <Element
                      isEditPage={isEditPage}
                      isConversionPage={isConversionPage}
                      isInvoice={isInvoice}
                      isTaxInvoice={isTaxInvoice}
                      isPurchaseBill={isPurchaseBill}
                      isEstimate={isEstimate}
                      isCreditNote={isCreditNote}
                      isDebitNote={isDebitNote}
                      isExpenseBill={isExpenseBill}
                    />
                  </Layout>
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </>
  );
}

export default App;

